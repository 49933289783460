body{
  padding: 0px;
  margin: 0px;
}

.container{
  width: 100%;
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.row{
  width: 100%;
  height: 100%;
}