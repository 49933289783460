.container{
    width: 100%;
    height: auto;
    display: flex;
    justify-content: space-between;
}

.filtersContainer{
    height: auto;
    display: flex;
    overflow: auto;
    padding: 15px;
    padding-top: 0px;
    align-items: center;
}

.wrapper{
    padding-right: 15px;
    width: auto;
    display: flex;
    flex-wrap: wrap;
}

.dateRange{
    border-radius: 5px;
    margin-top: 5px;
    position: absolute;
    left: 15px;
    top: 70px;
    z-index: 9999;
}

.confirm{
    width: 100%;
    padding: 15px 0px;
    cursor: pointer;
    text-align: center;
    color: #326dff;
    background: #eff2f7;
    border-bottom: 10px;
}

.inputContainer{
    width: 200px;
    padding-top: 5px;
}

.sumContainer{
    display: flex;
    width: 100%;
    padding: 10px;
    justify-content: flex-start;
}

.sumContainer span{
    padding-left: 15px;
    font-weight: 600;
}

.icon{
    width: 28px;
    height: 28px;
    display: flex;
    cursor: pointer;
    margin-right: 15px;
}

.icon img{
    width: 100%;
    height: 100%;
}

.control{
    display: flex;
    align-items: center;
    margin-left: 10px;
}

.topHeader{
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
}

.edit{
    width: 7px;
    height: 7px;
    background: #326dff;
    border-radius: 100%;
    position: absolute;
    right: 5px;
    top: 5px;
}

.delete{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    cursor: pointer;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    align-items: center;
}

.delete:hover span{
    display: none;
}

.delete:hover{
    background-image: url('../../img/trash.svg');
    background-position: center;
    background-size: 16px;
    background-repeat: no-repeat;
}