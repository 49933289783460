.containerForm{
    max-width: 450px;
    width: 100%;
    height: auto;
    display: flex;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    flex-wrap: wrap;
}

.row{
    width: 100%;
    height: 100%;
    display: flex;
    flex-wrap: wrap;
    box-shadow: -2px 1px 47px -21px rgba(0,0,0,0.76);
    background: #fff;
    margin: 15px;
    padding: 25px 35px;
}

.form{
    width: 100%;
    height: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.title{
    font-size: 22px;
    color: #326dff;
    font-weight: 500;
    padding-bottom: 5px;
}

.reset{
    margin: auto;
    margin-top: 20px;
    color: #828282;
    font-size: 14px;
    cursor: pointer;
}